import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <header id="header">
      <svg width="406" height="136" viewBox="0 0 406 136" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M211.493 63.2V49.88H191.486V42.3546H209.092V29.7871H191.486V23.24H211.493V9.92001H173.72V63.2H211.493ZM252.147 63.2L267.192 20.8H248.306L241.103 47.3376L233.821 20.8H214.934L229.979 63.2H252.147ZM292.561 63.68C303.925 63.68 312.728 56.9035 314.649 47.8683H295.842C295.202 49.1483 294.002 50.353 291.681 50.353C289.28 50.353 287.119 49.1483 286.879 44.8565H315.369C315.529 43.8024 315.609 42.5977 315.609 41.5435C315.609 28.2165 306.086 20.16 292.561 20.16C279.036 20.16 269.513 28.3671 269.513 41.92C269.513 55.473 279.036 63.68 292.561 63.68ZM297.603 37.6H287.039C287.599 34.6442 289.6 33.28 292.561 33.28C295.202 33.28 297.603 34.6442 297.603 37.6ZM338.977 63.2V40.7118C338.977 36.6504 341.378 34.1685 345.299 34.1685C349.141 34.1685 351.622 36.6504 351.622 40.7118V63.2H369.308V38.6059C369.308 27.6251 363.226 20.48 352.822 20.48C346.02 20.48 341.538 23.5637 338.977 27.249V20.7809H321.211V63.2H338.977ZM405.08 63.2V48.9872H400.839C398.518 48.9872 397.557 48.0848 397.557 46.1296V34.6992H404.92V20.7872H397.557V10.56H379.791V20.7872H374.189V34.6992H379.791V45.8288C379.791 58.9888 387.794 63.2 398.358 63.2H405.08Z" fill="black"/>
<path d="M172.12 115.36V102.983H155V63.2H137.24V115.36H172.12ZM199.16 116C212.76 116 223 107.944 223 94.64C223 81.3362 212.76 73.28 199.16 73.28C185.56 73.28 175.32 81.3362 175.32 94.64C175.32 107.944 185.56 116 199.16 116ZM199.16 101.76C195.96 101.76 193.32 99.5579 193.32 94.64C193.32 89.7221 196.04 87.52 199.16 87.52C202.28 87.52 205 89.7221 205 94.64C205 99.5579 202.28 101.76 199.16 101.76ZM250.04 116C263.64 116 273.88 107.944 273.88 94.64C273.88 81.3362 263.64 73.28 250.04 73.28C236.44 73.28 226.2 81.3362 226.2 94.64C226.2 107.944 236.44 116 250.04 116ZM250.04 101.76C246.84 101.76 244.2 99.5579 244.2 94.64C244.2 89.7221 246.92 87.52 250.04 87.52C253.16 87.52 255.88 89.7221 255.88 94.64C255.88 99.5579 253.16 101.76 250.04 101.76ZM297.24 135.36V109.746C299.4 113.51 303.64 115.946 309.64 115.946C320.04 115.946 328.28 108.048 328.28 94.6131C328.28 81.1784 320.04 73.28 309.64 73.28C303.64 73.28 299.4 75.716 297.24 79.4806V73.7967H279.48V135.36H297.24ZM303.72 101.6C300.04 101.6 297.16 99.1823 297.16 94.64C297.16 90.0977 300.04 87.68 303.72 87.68C307.4 87.68 310.28 90.0977 310.28 94.64C310.28 99.1823 307.4 101.6 303.72 101.6Z" fill="black"/>
<path d="M99.0343 1H16.6208C7.99369 1 1 7.99369 1 16.6208V99.0343C1 107.661 7.99369 114.655 16.6208 114.655H99.0343C107.661 114.655 114.655 107.661 114.655 99.0343V16.6208C114.655 7.99369 107.661 1 99.0343 1Z" fill="black" stroke="#5A5A5A" strokeWidth="0.53865"/>
<path fillRule="evenodd" clipRule="evenodd" d="M86.473 30.9887V52.0443C86.473 52.1676 86.4071 52.2816 86.3002 52.3431L58.0319 68.6155C57.8669 68.7105 57.6561 68.6538 57.5611 68.4888C57.531 68.4364 57.5151 68.3771 57.5151 68.3168V47.4627C57.5151 47.34 57.5803 47.2266 57.6863 47.1649L85.9546 30.6908C86.1191 30.5949 86.3302 30.6506 86.4261 30.8151C86.4568 30.8678 86.473 30.9277 86.473 30.9887Z" fill="#C3C3C3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M57.5151 14.5202V35.6509L67.5155 41.4203L85.9548 30.6947C86.1194 30.599 86.1752 30.388 86.0795 30.2234C86.0489 30.1708 86.0048 30.1272 85.9519 30.0971L58.0303 14.2205C57.8648 14.1264 57.6543 14.1843 57.5602 14.3498C57.5307 14.4017 57.5151 14.4604 57.5151 14.5202Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M57.5151 14.5193V35.6509L47.5461 41.4203L28.9925 30.6947C28.8276 30.5995 28.7712 30.3886 28.8665 30.2238C28.8972 30.1706 28.9416 30.1267 28.995 30.0964L57.0004 14.2194C57.166 14.1255 57.3764 14.1837 57.4703 14.3493C57.4997 14.4012 57.5151 14.4597 57.5151 14.5193Z" fill="#C3C3C3"/>
<path fillRule="evenodd" clipRule="evenodd" d="M28.4711 30.9865V84.9173C28.4711 85.0417 28.5382 85.1565 28.6466 85.2176L57.0011 101.201C57.167 101.294 57.3772 101.236 57.4707 101.07C57.4998 101.018 57.5151 100.96 57.5151 100.9V79.5688L47.5461 74.019V41.4242L28.9885 30.6881C28.8237 30.5927 28.6128 30.649 28.5175 30.8138C28.4871 30.8663 28.4711 30.9258 28.4711 30.9865Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M57.5151 79.563V100.9C57.5151 101.09 57.6695 101.244 57.8599 101.244C57.9193 101.244 57.9778 101.229 58.0296 101.2L86.2979 85.2133C86.4061 85.1522 86.473 85.0375 86.473 84.9132V63.6459C86.473 63.4555 86.3186 63.3011 86.1282 63.3011C86.0683 63.3011 86.0095 63.3167 85.9575 63.3464L57.5151 79.563Z" fill="#C3C3C3"/>
</svg>

      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    </header>
  );
}
