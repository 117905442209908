import React from 'react';

const checkboxes = [
  {
    name: 'organizer',
    key: 'organizer',
    label: 'Organizer',
  },
  {
    name: 'speaker',
    key: 'speaker',
    label: 'Speaker',
  },
  {
    name: 'sponsor',
    key: 'sponsor',
    label: 'Sponsor',
  },
  {
    name: 'attendee',
    key: 'attendee',
    label: 'Attendee',
  },
  {
    name: 'vendor',
    key: 'vendor',
    label: 'Vendor',
  },
];


// const CheckboxContainer = ({ checkboxes }) => {
//   const [checkedItems, setCheckedItems] = useState(new Map())

//   const handleChange = (e) => {
//     const item = e.target.name;
//     const isChecked = e.target.checked;
//     console.log("change", checkedItems, checkedItems.set(item, isChecked))
//   }

//   return (
//     <>
//     {checkboxes.map(item => (
//      <React.Fragment key={item.key}>
//       <input id={item.key} type="checkbox" name={item.name} checked={checkedItems.get(item.name)} onChange={handleChange} />
//       <label htmlFor={item.key}>
//         {item.name}
//       </label>
//      </React.Fragment>
//     ))}
//     </>
//   )
// }


export const EmailForm = () => {

    return (
      <form id="interest-form" name="interest-form" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/thankyou">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="interest-form"/>
        <p>Request to join the Alpha</p>
        <div className="input-group">
          <input
            required
            type="text"
            name="firstName"
            id="firstName"
            placeholder="First Name"
          />
          <input
          required
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
          />
        </div>
        <input
          required
          type="email"
          name="email"
          id="email"
          placeholder="Email"
        />
        <div className="interested-group">
          <p>I am interested as a:</p>
          { checkboxes.map(item => (
            <React.Fragment key={item.key} >
            <input id={item.key} type="checkbox" name={item.name}/>
            <label htmlFor={item.key}>
              {item.label}
            </label>
            </React.Fragment>
          ))}
        </div>
        <div>
        <input id="notificationsEnabled" type="checkbox" name="notificationsEnabled" required/>
            <label htmlFor="notificationsEnabled">
              I agree to be contacted via email for updates regarding Event Loop
            </label>
        </div>
        <input type="submit" value="Subscribe" />
      </form>
    );
}

export default EmailForm;
