module.exports = {
  siteTitle: 'Event Loop', // <title>
  manifestName: 'EventLoop',
  manifestShortName: 'EventLoopLanding', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#E5E5E5',
  manifestThemeColor: '#E5E5E5',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'The next generation of event software',
  subHeading:
    "What if online events didn't feel like an afterthought?",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/event-loop',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/eventloophq',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@eventloop.ai',
    },
  ],
};
